<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <div>
                    <b-card-group deck>
                        <b-card header-tag="header" footer-tag="footer">
                            <template v-slot:header>
                                <h6 class="mb-0">Báo cáo thống kê Funcard</h6>
                            </template>
                            <div class="filter-transaction">
                                <div class="row">
                                    <div class="col-md-5 mb-6">
                                        <label>Thời gian:</label>
                                        <div class="input-daterange input-group" id="kt_datepicker">
                                            <input
                                                type="date"
                                                v-model="filter.start_date"
                                                class="form-control datatable-input"
                                                name="start"
                                                placeholder="Từ"
                                            />
                                            <div class="input-group-append">
                                            <span class="input-group-text">
                                              <i class="la la-ellipsis-h"></i>
                                            </span>
                                            </div>
                                            <input
                                                type="date"
                                                v-model="filter.end_date"
                                                class="form-control datatable-input"
                                                name="end"
                                                placeholder="Đến"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-md-3 mb-6">
                                        <label>Game:</label>
                                        <select v-model="filter.game_id" class="form-control" id="game_id">
                                            <option value="">Tất cả</option>
                                            <option v-for="item in games" :key="item.game_id" :value="item.game_id">
                                                {{ item.game_id }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-md-4 mt-6">
                                        <div class="col-lg-12 text-right">
                                            <button class="btn btn-primary btn-primary--icon" @click="getList">
                                              <span>
                                                <i class="la la-search"></i>
                                                <span>Tìm kiếm</span>
                                              </span>
                                            </button>
                                            &nbsp;
                                            <button class="btn btn-primary btn-primary--icon" @click="$bvModal.show(modalId)">
                                                <i class="la la-cloud-download"></i>
                                                <span>Xuất excel</span>
                                            </button>

                                            <button class="btn btn-warning btn-warning--icon ml-3"
                                                    @click="$bvModal.show(modalImportId)">
                                                <i class="la la-redo-alt"></i>
                                                <span>Đồng bộ dữ liệu</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4 al">
                                        <b-form-group label="Sắp xếp theo (*):">
                                            <b-form-select
                                                v-model="reportSort.value"
                                                :options="reportSort.option"
                                                :change="getList"
                                            ></b-form-select>
                                        </b-form-group>
                                    </div>
                                </div>
                            </div>

                            <p v-if="!showTable">Không có dữ liệu</p>
                            <div class="table_wrapper" v-if="showTable">
                                <b-table
                                    :items="items"
                                    :fields="fields"
                                    ref="table"
                                    striped
                                    hover
                                    responsive
                                    caption-top
                                >
                                    <template #cell(index)="data">
                                        {{ (currentPage - 1) * 25 + (data.index + 1) }}
                                    </template>
                                    <template #cell(gmv)="data">
                                        {{ numberFormat(parseInt(data.item.gmv)) }}
                                    </template>
                                    <template #cell(quantity)="data">
                                        {{ numberFormat(parseInt(data.item.quantity)) }}
                                    </template>
                                </b-table>
                                <div>
                                    <b-pagination-nav
                                        v-model="currentPage"
                                        :link-gen="createLinkPage"
                                        :number-of-pages="paginate.totalPage"
                                        use-router
                                        first-number
                                        last-number
                                        align="right"
                                    ></b-pagination-nav>
                                </div>
                            </div>
                        </b-card>
                    </b-card-group>
                </div>
            </div>
        </div>
        <alert-dialogue ref="confirmDialogue"></alert-dialogue>
        <export-modal :modal-id="modalId" @exportData="exportList"></export-modal>
        <b-modal :id="modalImportId" title="Yêu cầu import dữ liệu">
            <br />
            <div class="d-block text-center">
                <input
                    type="file"
                    class="form-control"
                    placeholder="Chọn file"
                    @change="onFileChange"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                />
            </div>
            <template #modal-footer="{ ok, cancel }">
                <b-button size="sm" variant="danger" @click="cancel"> Hủy </b-button>
                <b-button size="sm" variant="success" @click="importFuncard">
                    Import
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import Common from "@/core/mixins/common";
import BiRepository from "@/core/repositories/biRepository";
import ExportModal from "@/view/pages/components/ExportModal.vue";
import AlertDialogue from "@/view/pages/components/AlertModal.vue";

export default {
    name: "Funcard",
    mixins: [Common],
    components: {AlertDialogue, ExportModal},
    data() {
        return {
            fields: [
                {
                    label: "Game",
                    key: "game_id",
                },
                {
                    label: "GMV thanh toán",
                    key: "gmv",
                },
                {
                    label: "Số lượng thẻ",
                    key: "quantity",
                },
            ],
            filter: {},
            items: [],
            arisingIncrease: [],
            arisingDecrease: [],
            modalImportId: "import_report_funcard",
            modalId: "export_report_funcard",
            games: [],
            formInput:[],
            currentPage: 1,
            paginate: {
                limit: 25,
                page: 1,
                totalPage: 1,
                total: 0,
                skip: 0,
            },
            reportSort: {
                value: "gmv DESC",
                option: [
                    { value: "gmv DESC", text: "GMV tăng dần" },
                    { value: "gmv ASC", text: "GMV giảm dần" },
                    { value: "quantity DESC", text: "SL thẻ giảm dần" },
                    { value: "quantity ASC", text: "SL thẻ tăng dần" },
                ],
            },
            showTable: false,
        };
    },
    methods: {
        onFileChange(event) {
            this.formInput.file = event.target.files[0];
        },
        importFuncard() {
            if (!this.formInput.file) {
                this.notifyAlert("warn", "Vui lòng chọn tệp trước khi thực hiện import.");
                return;
            }

            const formData = new FormData();
            formData.append('file', this.formInput.file);

            this.$bus.$emit("show-loading", true);
            try {
                BiRepository.importReportFuncard(formData)
                    .then((response) => {
                        if (response.data.error_code) {
                            this.notifyAlert("warn", response.data.message);
                        } else {
                            this.notifyAlert(
                                "success",
                                "Import thành công"
                            );
                            this.$bus.$emit("show-loading", false);
                            this.$bvModal.hide(this.modalImportId);
                            return this.$router.push({ name: "funcard" });
                        }
                    });
            } catch (e) {
                this.$bus.$emit('show-loading', false);
            }
        },
        async getList() {
            try {
                this.$bus.$emit("show-loading", true);
                let params = this.filter;
                params.page = this.currentPage;
                params.limit = this.paginate.limit;
                params.sort = this.reportSort.value;
                let response = await BiRepository.getReportFuncard(params);
                this.$bus.$emit("show-loading", false);
                let body = response.data.data;

                if(body.data.data.length < 1) {
                    this.notifyAlert("warn", "Không có dữ liệu");
                    return true;
                }
                this.items = body.data.data;
                this.paginate.total = body.data.total;
                this.paginate.totalPage = body.data.last_page;
                this.games = body.games;
                this.showTable = true;
            } catch (e) {
                this.$bus.$emit("show-loading", false);
            }
        },
        exportList: async function () {
            try {
                this.$bus.$emit("show-loading", true);
                let params = this.filter;
                params.page = this.currentPage;
                let response = await BiRepository.exportReportFuncard(params);
                this.$bus.$emit("show-loading", false);

                if (response.data.error_code) {
                    this.notifyAlert("warn", response.data.message);
                    return true;
                }

                this.$bus.$emit("show-loading", false);
                this.$bvModal.hide(this.modalId);
                let link = window.location.origin + "/#/reconcile/export-data";
                let cfm = await this.$refs.confirmDialogue.show({
                    title: "Download File",
                    message: "File đang được xử lý, bạn có thể tải xuống sau ít phút <a href='" + link + "' target='_blank'>TẠI ĐÂY</a>",
                    okButton: "Ok",
                });

                if (!cfm) {
                    self.$bus.$emit("show-loading", false);
                    return (self.errorMessage = "Có lỗi khi lấy thông tin");
                }

            } catch (e) {
                this.$bus.$emit("show-loading", false);
            }
        },
        setCurrentPage(page) {
            this.currentPage = page;
        },
    },
    computed: {
        ...mapGetters(["layoutConfig"]),

        config() {
            return this.layoutConfig();
        },
    },
    created() {
        this.getList();
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [{title: "Thống kê Funcard"}]);
    },
    watch: {
        currentPage() {
            this.getList();
        },
    },
};
</script>
<style lang="scss">
.table_wrapper {
    overflow-x: auto;
    white-space: nowrap;
    padding-top: 20px;
    max-height: 800px;

    th {
        border: 1px solid #dedfe1 !important;
        text-align: center;
        padding: 20px 10px 10px 10px;
    }

    td {
        padding: 15px;
        border: 1px solid #ebedf3;
    }

    thead {
        position: sticky;
        top: -21px;
    }
}
</style>
